.text {
    font-size: 1.2em;
}

.news {
    white-space: normal; 
    font-size: 11px;
}

.websiteTitle {
    font-size: 3em;
    text-align: center;
    font-family: 'Sora', sans-serif;
    color: #ffffff;
    background-image: linear-gradient(rgba(214, 32, 0, 0.959), firebrick 40%);
}


.linkText{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
 }

.websiteFooter {
    text-align: center;
    color: #ffffff;
    background-image: linear-gradient(rgba(214, 32, 0, 0.959), firebrick 50%);

}

.sourceTitle {
    background-color: rgb(128, 128, 128);
    color: white;
    border-radius: 5px;
    text-align: left;
    padding: 5px 50px 5px 50px;
}

.feedContainer {
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(128, 128, 128);
}

.fixedContainer {
    min-height: 520px;
}

.feedEntry {
    display: flex;
    align-items: center;
    line-height: 1.5;
    min-height: 2.1em;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
}

a {
    color:rgb(24, 67, 196) ;
}

a:hover{
    color: rgb(93, 62, 165) ;
}
p, a span {
    color: #000;
}

.developerDetails {
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
}

.developerName {
    color : rgb(135, 202, 48);
}

.developerName:hover {
    color : rgb(135, 202, 48);
}
